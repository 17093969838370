@import (reference) "mobile.less";

@media(min-width: 768px) {
    .wrapper {
        width: 768px;
        padding: 0 9px;
    }

    h1 {
        font-size: 34px;
        line-height: 35px;
        margin-bottom: 10px;
        margin-left: 9px;

        .city {
            display: inline;
        }

        .city_mobile {
            display: none;
        }

        .sup {
            margin-top: -9px;
        }
    }

    header {
        background-image: url(../img/header_t_p.png);

        &:after {
            top: 678px;
        }

        .wrapper {
            padding: 0;
        }

        .callback {
            margin-right: 9px;
        }

        .logo {
            width: 321px;
            height: 91px;
            margin-top: -50px;
            margin-bottom: 35px;
            margin-left: 9px;
        }

        .desc {
            font-size: 20px;
            line-height: 25px;
            margin-left: 9px;
            display: block;
        }

        .advantages {
            width: 360px;
            float: left;
            margin: 25px 0 25px 9px;
        }

        .delivery {
            float: right;
            width: 380px;
            margin-top: 77px;
            padding: 0 65px 12px 40px;

            &:before {
                top: -50px;
                width: 0;
                height: 0;
                border-right: 380px solid transparent;
                border-bottom: 50px solid #212121;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: -50px;
                width: 0;
                height: 0;
                left: 0;
                border-left: 380px solid transparent;
                border-top: 50px solid #212121;
            }
        }

        .form_back {
            float: right;
            width: 380px;
            margin-top: 0;
            
            &:after {
                content: "";
                position: absolute;
                bottom: 45px;
                right: 0;
                height: 5px;
                width: 768px;
                background: #ff8400;
                transform: matrix(1, 0.124, 0, 1, 0, 0);
            }

            form {                
                background-image: url(../img/header_form_d.png);
                padding: 55px 45px 66px;
            }
        }

        .map {
            float: left;
            width: 388px;
            margin-top: -24px;
            transform: matrix(1, 0.124, 0, 1, 0, 0);
            border: none;
            height: 351px;
            overflow: hidden;

            .ymap {
                transform: matrix(1, -0.124, 0, 1, 0, 0);
                height: 396px;
                top: -24px;
            }

            .address_desc {
                // transform: none;
            }
        }
    }

    section.price {
        background: transparent;

        &>.wrapper {
            width: 768px;
            padding: 0;

            &:before {
                content: "";
                position: absolute;
                width: 607px;
                height: 436px;
                background: url(../img/car.svg) center center no-repeat;
                background-size: contain;
                right: -40px;
                top: 180px;
                opacity: 0.02;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                width: 245px;
                background: #212121;
            }
        }

        h2 {
            margin-top: -100px;
            padding-top: 80px;
            width: 245px;
            padding-left: 45px;

            .wrapper {
                width: 100%;
                padding: 0;
            }
        }

        .title {
            width: 245px;

            .wrapper {
                width: 100%;
                padding-left: 70px;
                
                .icon {
                    left: 15px;
                }
            }
        }

        .content {
            position: absolute;
            max-height: 0;
            opacity: 0;
            z-index: 2;
            width: 520px;
            right: 0;
            top: 164px;
            background: transparent;

            .wrapper {
                width: 100%;
                padding: 0;
            }

            .for_sales {
                display: none;
            }
        }

        .title.active + .content {
            max-height: none;
            opacity: 1;
            z-index: 3;
        }

        .desktop_sales {
            display: block;
            position: absolute;
            width: calc(~"100% + 40px");
            bottom: 0;
            font-size: 14px;
            font-family: @light;
            text-transform: uppercase;
            text-align: right;
            right: -40px;
            padding-right: 50px;
            z-index: 1;

            sup {
                font-size: 10px;
            }

            span {
                display: block;
                font-family: @regular;
                font-size: 34px;
                color: #ff8400;
                border-bottom: 2px solid #fff;
                margin-right: -50px;
                padding-right: 50px;
                padding-bottom: 4px;
                margin-bottom: 5px;
                margin-top: 30px;
            }
        }
    }

     section.seconds60 {
        background: url(../img/seconds60_d.png) top center no-repeat;
        margin-top: -130px;
        height: 734px;
        margin-bottom: -50px;
        z-index: 2;

        &:before, &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 117px;
            height: 3px;
            width: 100%;
            background: #1d1d1d;            
            transform: matrix(1, 0.124, 0, 1, 0, 0);
        }

        &:after {
            top: auto;
            bottom: 115px;
        }

        .title {
            display: none;
        }

         .title_d {
            font-size: 26px;
            line-height: 30px;
            letter-spacing: 0.02em;
            font-family: @black;
            width: 330px;
            padding-top: 162px;
            margin-left: 9px;
            display: block;
            margin-bottom: 15px;

            span {
                color: #ff8400;
            }
        }

        .desc_d {
            display: block;
            font-size: 16px;
            line-height: 24px;
            width: 330px;
            margin-left: 9px;
        }

        .wrapper {
            width: 768px;
            position: relative;
            left: 0;
            margin: 0 auto;
            background: none;

            &:before, &:after {
                display: none;
            }
        }

        form {
            width: 430px;
            background: rgba(0, 0, 0, 0.4);
            position: absolute;
            right: 0;
            top: 87px;
            height: 444px;
            padding: 10px 9px 0 55px;

            &:before {
                content: "";
                position: absolute;
                top: -53px;
                left: 0;
                width: 0;
                height: 0;            
                border-right: 430px solid transparent;
                border-bottom: 53px solid rgba(0, 0, 0, 0.4);;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: -53px;
                left: 0;
                width: 0;
                height: 0;            
                border-left: 430px solid transparent;
                border-top: 53px solid rgba(0, 0, 0, 0.4);;
            }

            .form_title {
                display: block;
                font-size: 20px;
                line-height: 24px;
                color: #ff8400;
                font-family: @black;
            }
        }
     }

     section.equipment {
         padding-bottom: 40px;

        ul {
            width: 550px;
            margin: 0 auto;

            li {
                padding: 35px 225px 25px 35px;
                min-height: 210px;

                .img {
                    position: absolute;
                    top: 35px;
                    right: -20px;
                }

            }
        }
     }

     section.about {
         .nav {
             display: block;
             margin-bottom: 30px;

             a {
                 display: inline-block;
                 vertical-align: middle;
                 font-size: 16px;
                 color: #1d1d1d;
                 text-decoration: none;
                 text-transform: uppercase;
                 padding: 27px 18px;
                 margin-right: 38px;
                 transition-duration: 0.3s;

                 &:last-child {
                     margin-right: 0;
                 }

                 &:hover {
                     color: #ff8400;
                 }

                 &.active {
                     background: #ffa800;
                     color: #fff;
                 }
             }
         }

         ul.pluses {
            li {
                .icon {
                    top: 0!important;
                }

                &.gost .icon, &.call .icon {
                    top: -7px !important;
                }

                &.clients .icon {
                    top: 10px !important;
                }
            }
        }


         @media(max-width: 1023px) {
            ul.pluses {
                li {
                    display: inline-block;
                    vertical-align: top;
                    width: 240px;
                    padding-left: 0;
                    padding-top:  55px;
                    padding-right: 15px;

                    &:before {
                        left: 0;
                        top: 12px;
                    }

                    &.experience:before {
                        top: 0;
                    }

                    &.staff:before {
                        top: 0;
                    }
                    &.factory:before {
                        top: 5px;
                    }
                    &.department {
                        width: 265px;
                        padding-right: 10px;

                        &:before {
                            top: 5px;
                        }
                    }
                    &.clients {
                        width: 210px;
                        :before {
                        }
                    }
                    &.stock {
                        width: 265px;

                        &:before {
                            top: 0;
                        }  
                    } 
                    &.gost:before {
                        top: -6px;
                    }

                    &.call:before {
                        top: -6px;
                    }
                }

            }
         }

         .manufacture, .certificates, .lab, .park {
             position: relative;
             opacity: 0;
             height: 0;
             overflow: hidden;
             margin: 0;
             transition-duration: 0.4s;

             &.active {
                 opacity: 1;
                 z-index: 2;
                 height: auto;
             }
         }

         .manufacture {

             .caroufredsel_wrapper {
                 padding: 0 341px;
                 margin-left: -136px !important;

                 &:after {
                     width: 341px;
                     height: 188px;
                     left: 341px;
                 }
             }

             ul.manufacture-slider {
                 li {
                     width: 341px;
                     height: 188px;
                 }
             }
         }

         .lab {

             &.active {
                 margin-bottom: -75px;
                 min-height: 570px;
             }

             h3 {
                 margin-bottom: 50px;
             }

            .wrapper {
                width: 768px;
            }

                .pluses {
                    width: 380px;

                    @media(max-width: 1023px) {
                        li {
                            width: 100%;
                            display: block;

                            padding-top: 0;
                            padding-left: 70px;
                        }
                    }
                }

                p {
                    width: 370px;
                }

                img {
                    margin: 0;
                    margin-left: 40px;
                }

             form {
                 position: absolute;
                 right: 0;
                 top: 65px;
                 margin: 0;
                 width: 380px;
                 padding: 50px 60px 0 50px;
             }
         }

         .certificates { 
             ul.certificates-slider {
                li {
                    width: 217px;
                    height: 317px;
                    margin-right: 32px;
                }
             }
         }
         .park {

             .caroufredsel_wrapper {
                 padding: 0 341px;
                 margin-left: -136px !important;

                 &:after {
                     width: 341px;
                     height: 188px;
                     left: 341px;
                 }
             }

             ul.park-slider {
                 li {
                     width: 341px;
                     height: 188px;
                 }
             }
         }
     }

     section.clients {
        padding-bottom: 210px;         

         h2 {
             margin-bottom: 75px;
         }

        .big_clients .caroufredsel_wrapper {
            margin-left: 0!important;
            padding: 0 360px;
            
            &:after {
                width: 360px;
                height: 279px;
                left: 360px;
            }
                
        }

        ul.clients-slider {

            li {
                width: 360px;

                .for_click {
                    height: 279px;
                }

                img {
                    height: 279px;
                }

                &:after {
                    height: 279px;
                }
                

                    .title {
                        width: 750px;
                        margin-left: -360px;
                    }

                    ul {
                        margin-left: -320px;

                        li {
                            float: left;
                            width: 50%;
                        }
                    }
            }
        }

        .logos {
            li {
                width: 155px;
                height: 85px;
                margin-right: 30px;
            }
        }
    }

    section.watch {
        background: url(../img/watch_t_p.png) top center no-repeat;

        &:before {
            top: 118px;
        }

        &:after {
            bottom: 109px;
        }

        .connect {
            float: left;
            width: 390px;
        }

        form {
            float: right;
            width: 280px;
            padding: 0 0 0 40px;
            margin-top: 75px;

            .form_title {
                margin-bottom: 45px;
            }

            .button {
                margin-top: 35px;
            }
        }
    }

    section.team {

        .wrapper {
            padding-bottom: 100px;

            &:after {
                width: 160px;
                height: 125px;
                right: 3px;
                bottom: 2px;
            }
        }

        ul {
            width: 490px;

            li {
                width: 235px;

                .desc {
                    width: 220px;
                }
            }
        }
    }

    footer {
        text-align: center;
        margin-top: 75px;

        .logo {
            width: 300px;
            margin: 0 auto 35px;
        }

        .phone, .email, .button {
            display: inline-block;
            vertical-align: middle;
            margin: 0 40px 30px;
        }

        .phone {
            margin-left: 0;
        }

        .button {
            margin-right: 0;
        }

        .hr {
            width: 100%;
            margin-bottom: 35px;
        }

        .polite_a {
            float: left;
        }

        .seo_id {
            float: right;
        }

        .cards {
            margin-bottom: -27px;
        }
    }
}


@media(min-width: 1024px) {
    .wrapper {
        width: 1007px;
        padding: 0 3px;
    }

    h1 {
        white-space: pre-line;
    }

    header {
        background-image: url(../img/header_t_l.png);

        .form_back {
            &:after {
                bottom: 61px;
                width: 1007px;
            }
        }

        .advantages {
            width: 645px;
            margin: 130px 5px 0;

            li {
                display: inline-block;
                vertical-align: top;
                padding-left: 0;
                padding-top: 50px;
                margin: 0;

                &.lab {
                    width: 210px;
                    margin-right: 20px;
                }

                &.upper {
                    width: 155px;
                    margin-right: 40px;
                    margin-top: 32px;
                }

                &.gost {
                    width: 185px;
                    margin-top: 58px;
                }
                
                .icon {
                    top: 0;
                    transform: none;
                }
            }
        }

        .delivery {
            width: 350px;
            padding: 0 50px 12px 30px;
            margin-top: 105px;

            &:before {
                top: -45px;
                border-right: 350px solid transparent;
                border-bottom: 45px solid #212121;
            }

            &:after {
                bottom: -45px;
                border-left: 350px solid transparent;
                border-top: 45px solid #212121;
            }
        }

        .form_back {
            margin-top: -2px;
            width: 350px;

            form {
                padding: 55px 22px 66px;
            }
        }

        .map {
            width: 657px;
            margin-top: -41px;

            .ymap {
                top: -41px;
                height: 430px;
            }
        }
    }

    section.price {
        &>.wrapper {
            width: 1007px;

            &:before {
                width: 810px;
                height: 582px;
                top: 130px;
                right: -90px;
            }

            &:after {
                width: 330px;
            }

        }

        h2 {
            width: 330px;
            margin-top: -125px;
            padding-top: 105px;
        }

        .title {
            width: 330px;

        }

        .content {
            width: 670px;
            top: 189px;
        }

        .table {

            .td {
                &:first-child {
                    padding-left: 30px;
                    width: 170px;
                }

                &:last-child {
                    padding-right: 30px;
                }
            }
        }
    }

    section.seconds60 {
        .wrapper {
            width: 1007px;
        }

        .title_d, .desc_d {
            width: 380px;
        }

        form {
            width: 590px;
            height: 425px;
            right: -40px;
            padding: 0 55px 0 85px;
            top: 107px;

            &:before {
                top: -73px;
                border-right: 590px solid transparent;
                border-bottom: 73px solid rgba(0, 0, 0, 0.4);
            }
            &:after {
                bottom: -73px;
                border-left: 590px solid transparent;
                border-top: 73px solid rgba(0, 0, 0, 0.4);
            }

            label {
                margin: 20px 0;
                width: 100%;
            }

            label.name, label.phone,label.short {
                width: 200px;
                display: inline-block;
                vertical-align: top;
            }

            label.name, label.brand {
                margin-right: 44px;
            }

            label.brand {
                margin-top: 19px;
            }

            .button {
                margin-right: 25px;
                margin-top: 20px;
            }
        }
    }

    section.equipment {

        ul {
            width: 100%;

            li {
                display: inline-block;
                vertical-align: top;
                width: 425px;
                padding: 35px 145px 15px 15px;
                min-height: 280px;

                &:nth-child(2n-1) {
                    margin-right: 80px;
                }

                .img {
                    width: 205px;
                    height: 150px;
                    position: absolute;
                    top: 35px;
                    right: -65px;
                }

            }
        }
    }

    section.about {

        .nav {
            a {
                padding-left: 90px;
                position: relative;
                margin-right: 25px;

                .icon {
                    display: block;
                    position: absolute;
                    width:50px;
                    height: 50px;
                    left: 25px;
                    top: 50%;
                    transform: translateY(-50%);
                    fill: currentColor;
                }
             }
         }

        ul.pluses {

            li {
                display: inline-block;
                vertical-align: top;
                width: 319px;
                margin-right: 15px;

                &:nth-child(3n) {
                    margin-right: 0;
                }
                
                &.clients .icon {
                    top: -10px !important;
                }
                
                &.gost .icon, &.call .icon {
                    top: 5px !important;
                }
            }
        }

         .manufacture {
             ul.manufacture-slider {
                 width: 1024px;
                 margin-left: -12px;

                 li {
                     &:after {
                         display: none;
                     }
                 }
             }
         }

         .certificates {
             ul.certificates-slider {
                 li {
                    margin: 0 40px;
                    
                    &:first-child {
                        margin-left: 95px;
                    }  
                 }
             }
         }

         .park {
             ul.park-slider {
                 width: 1024px;
                 margin-left: -12px;

                 li {
                     &:after {
                         display: none;
                     }
                 }
             }
         }

         .lab {

            .wrapper {
                width: 1007px;
            }

            ul.pluses {
                width: 675px;
            }

            form {
                right: -30px;
            }

            p {
                width: 650px;
            }

            img {
                float: left;
                margin: 0;
                width: 330px;

                &.sec {
                    display: block;
                }
            }
         }
     }

     section.clients {

        ul.clients-slider {

            li {
                    .title {
                        width: 760px;
                        margin-left: -240px;
                    }

                    ul {
                        margin-left: -170px;
                        width: 730px;
                    }
            }
        }

        .logos {
            text-align: left;
            li {
                width: 155px;
                height: 85px;
                margin-right: 40px;
            }
        }
    }

    section.watch {
        margin-top: -40px;

        &:after {
            bottom: 99px;
            height: 6px;
        }

        .connect {
            width: 520px;

            .pluses {
                margin-bottom: 36px;

                .text {
                    width: 410px;
                }
            }

            .title {
                width: auto;
                margin-top: 63px;
                padding-top: 48px;
            }

            .content {
                width: 450px;
            }
        }

        form {
            width: 405px;
            padding: 0 0 0 85px;
            margin-top: 120px;

            .form_title {
                padding-top: 0;
                padding-left: 56px;
            }

            .polite {
                margin-top: 30px;
            }

            .button {
                margin-top: 45px;
            }
        }
    }

    section.team {

        .wrapper {
            padding-bottom: 150px;

            &:after {
                right: 5px;
            }
        }

        ul {
            width: 100%;

            li {
                width: 235px;
                margin-left: 20px;

                &:nth-child(4n-3) {
                    margin-left: 0; 
                }
            }
        }
    }

    footer {
        margin-top: 60px;

        .phone, .email, .button {
            margin: 0 93px 40px;
        }

        .phone {
            margin-left: 40px;
        }

        .button {
            margin-right: 0;
        }
    }
}

